.save_deploy_box {
  margin: 1rem 0;

  h4 {
    font-size: 2rem !important;
    color: var(--color-dark) !important;
    font-weight: 600 !important;
  }

  p {
    font-size: 1.3rem !important;
    color: var(--color-darkgrey);
    margin: 1rem 0;
  }
}