.selectiondiv-box {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;

  &.d-block {
    display: block;
  }

  &.flex-none {
    display: block;
  }

  .selectionDiv {
    display: block;
    align-items: center;
    border-radius: 0.4rem;
    padding: 0.4rem;
    margin-right: 1rem !important;
    margin-top: 0 !important;
    text-transform: capitalize;
    box-shadow: none;

    &.fullWidth {
      width: 100%;
      max-width: 100%;

      .dropdown-ap {
        width: 100% !important;
      }
    }

    &.border {
      border: 1px solid var(--color-primary);
    }

    &.borderGrey {
      border: var(--border);
    }

    &.bn {
      border: none;
    }

    &.searchFlex {
      display: flex !important;
      align-items: center;
    }

    p {
      font-size: 1.3rem;
      color: var(--color-darkgrey) !important;
      white-space: nowrap;
      display: inline;
      text-transform: capitalize;
      margin-right: 0;

      @media screen and (max-width: 991px) {
        display: block;
        width: 100%;
        margin-bottom: 0.5rem;
        display: none;
      }
    }

    .dropdown-ap {
      .dropdown {
        min-width: 100%;
        margin: 0;
        height: 100%;
        padding: 0 1rem;
        position: relative;

        .MuiSelect-outlined {
          display: block;
          align-items: center;
          width: 100%;
          padding: 0 1.4rem 0 0 !important;
          font-size: 1.3rem !important;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: flex;
          align-items: center;

          @media screen and (max-width: 991px) {
            height: 100%;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            display: flex;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border: none !important;
          border: 1px solid var(--color-primary) !important;
          background-color: var(--color-lightsuccess);
        }

        .MuiSelect-icon {
          right: 2px;
          font-size: 2.4rem;
        }

        &.Mui-select2 {
          .MuiSelect-select {
            width: 100% !important;

            .MuiBox-root {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }

        @media screen and (max-width: 1199px) {
          // display: inline-block !important;
          width: 100%;
          height: 3.2rem;
          // padding: 0.5rem !important;
        }

        &.icon_set {
          position: relative;

          svg.MuiSvgIcon-root {
            display: none;
          }

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0.6rem;
            transform: translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='AccessTimeRoundedIcon'%3E%3Cpath d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-.22-13h-.06c-.4 0-.72.32-.72.72v4.72c0 .35.18.68.49.86l4.15 2.49c.34.2.78.1.98-.24.21-.34.1-.79-.25-.99l-3.87-2.3V7.72c0-.4-.32-.72-.72-.72z'%3E%3C/path%3E%3C/svg%3E");
            height: 2rem;
            width: 2rem;
            background-repeat: no-repeat;
          }
        }
      }

      .MuiButtonBase-root:hover,
      .MuiButtonBase-root.Mui-selected {
        background-color: var(--color-lightPrimary) !important;
      }

      @media screen and (max-width: 1199px) {
        // display: inline-block !important;
        width: 100%;
      }
    }

    &.Mui-select2 {
      .MuiSelect-select {
        width: 100% !important;

        .MuiBox-root {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    @media screen and (max-width: 991px) {
      width: calc(50% - 2rem);
      margin: 1rem;
      margin-left: 0 !important;
      display: block;
      border: none;
      padding: 0 !important;
      height: auto;
    }

    @media screen and (max-width: 767px) {
      width: calc(100% - 2rem);
    }

    @media screen and (max-width: 576px) {
      margin-bottom: 0;
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  &.fullWidth {
    .selectionDiv {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    justify-content: center;
    align-items: flex-end;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 1rem !important;
    width: calc(100% - 1rem);
  }
}

.MuiMenu-paper {
  background-color: var(--color-white) !important;
  border: var(--border);
  max-height: 40vh !important;

  &::before {
    background-color: var(--color-white) !important;
  }
}
