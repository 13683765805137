.contactDetails-box {
  h2 {
    font-size: 2.8rem;
    color: var(--color-dark);
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  p {
    color: var(--color-darkgrey);
    font-size: 1.4rem;
  }

  .contactForm-bg {
    position: relative;
    padding: 2.5rem;
    border-radius: 1.5rem;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--color-darkblue);
      border-radius: 1rem;
      z-index: -1;
      opacity: 0.8;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    h2 {
      font-size: 3rem;
      color: var(--color-light);
      margin-bottom: 1rem;
      font-weight: 600;
    }

    p {
      color: var(--color-light);
      font-size: 1.8rem;
      opacity: 0.8;
    }

    ul.contactDetails {
      margin-top: 2rem;
      display: flex;
      align-items: top;

      li.contactDetails-item {
        padding: 0;
        display: block;

        &:first-child {
          margin-right: 2.6rem;
          height: 4rem;
          width: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 100%;
            width: 100%;
          }

          @media screen and (max-width: 767px) {
            margin-right: 1.6rem;
          }
        }

        &:last-child {
          p {
            display: block !important;
            color: var(--color-light);
            margin-bottom: 0.6rem;
            width: 100%;
            opacity: 0.8;
            font-size: 1.8rem;

            @media screen and (max-width: 767px) {
              font-size: 1.4rem;
            }
          }
        }
      }

      p.social_title {
        font-size: 1.4rem;
      }

      .social_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 2rem;
        max-width: 2rem;
        padding: 0.6rem;
        border-radius: 0.4rem;
        background-color: var(--color-white) !important;
        border: var(--border);
        margin-right: 0.6rem;

        img {
          height: 100%;
          width: 100%;
          position: inherit;
          z-index: 0;
        }
      }

      @media screen and (max-width: 991px) {
        margin-top: 3rem;
      }
    }

    @media screen and (max-width: 991px) {
      padding: 1rem;
    }
  }
}

.contactForm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;

  .submitBox {
    width: 100%;
    margin-top: 3.5rem;
    display: flex;
    justify-content: end;

    button.submit-btn {
      background-color: var(--color-darkblue);
      padding: 1rem 4rem;
      border-radius: 0.5rem;
      font-size: 1.6rem;
      color: var(--color-white);
      border: none;
      outline: none;
      display: block;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }
}

.disabled-inputField {
  cursor: not-allowed;
  background-color: var(--color-light);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

// .disabled:hover {
//   background-color: var(--color-light) !important;
// }
