Button.autoTrade-btn {
  padding: 0.6rem 1rem !important;
  border-radius: 0.4rem;
  color: #ffffff;
  font-size: 1.2rem !important;
  margin-right: 1rem;
  cursor: no-drop;
  height: auto !important;
  min-height: auto !important;

  &.on {
    background-color: var(--color-darkPrimary);
  }

  &.off {
    background-color: var(--color-danger);
  }
}
