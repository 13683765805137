div.sidebar {
  width: 7rem;
  transition: 0.5s;
  position: sticky;
  top: 6rem;
  background-color: var(--color-white);
  height: calc(100vh - 8.4rem);
  border-right: var(--border);
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ul.sidebarList {
    li.sidebarList-item {
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      padding: 0;

      a.sidebarList-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.2rem;
        width: 2.2rem;
        padding: 0.5rem;
        border-radius: 0.5rem;

        .sidebarList-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: var(--color-icon);
          height: 2rem;
          width: 2rem;
        }

        p {
          font-size: 1.4rem;
          color: var(--color-icon);
          display: none;
          margin-left: 1rem;

          @media screen and (max-width:991px) {
            display: block;
          }
        }

        &.active,
        &:hover {
          background-color: var(--color-lightPrimary);

          .sidebarList-icon {
            svg {
              path {
                fill: var(--color-primary);
              }
            }
          }
        }

        @media screen and (max-width:991px) {
          min-width: 100%;
          justify-content: left;
        }
      }

      @media screen and (max-width:991px) {
        display: block;
        padding: 0 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }

  // @media screen and (max-width:1199px) {
  //   width: 8rem;
  // }

  @media screen and (max-width:991px) {
    width: 30rem;
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    z-index: 55;
    background-color: var(--color-white);

    &.open {
      left: 0;
    }
  }
}