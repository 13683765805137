.tableActions {
  padding: 0 0.5rem;

  div.actionButton {
    button {
      margin: 0 0.5rem;
      min-height: 2.8rem;
      min-width: 2.8rem;
      border-radius: 0.5rem;
      padding: 0.5rem;

      svg {
        height: 1.6rem;
        width: 1.6rem;
      }

      &.edit {
        background-color: var(--color-success);
        margin-left: 0;
        color: var(--color-white);
      }

      &.save {
        background-color: var(--color-primary);
        color: var(--color-white);
      }

      &.del {
        background-color: var(--color-danger);
        color: var(--color-white);
      }

      &.buy {
        background-color: #24a959 !important;
        color: #ffffff !important;

        svg {
          height: 1.3rem;
          width: 1.3rem;
        }
      }

      &.sell {
        background-color: #ff0000 !important;
        color: #ffffff;

        svg {
          height: 1.3rem;
          width: 1.3rem;
        }
      }

      &.bsdel {
        background-color: var(--color-danger);
        color: #ffffff;
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0.8rem;
  }
}
