.permissionsAccess {
  padding: 2rem;

  h4.title {
    font-size: 2rem;
    color: var(--color-darkgrey);
    font-weight: 500;
  }
}

.permission-box {
  padding: 2rem;

  .permission-item {
    padding: 0.5rem 1.5rem !important;
    margin-bottom: 0.6rem;

    .MuiFormControlLabel-root {
      .MuiButtonBase-root {
        .MuiSvgIcon-root {
          font-size: 2.5rem;
          color: var(--color-dark);
        }

        &.Mui-checked {
          color: var(--color-primary) !important;

          .MuiSvgIcon-root {
            font-size: 2.5rem;
            fill: var(--color-primary) !important;
          }
        }
      }

      .MuiFormControlLabel-label {
        font-size: 1.4rem;
        text-transform: capitalize;
        color: var(--color-darkgrey);

        @media screen and (max-width:599px) {
          font-size: 1.2rem !important;
        }
      }
    }

    @media screen and (max-width:599px) {
      padding: 0.6rem !important;
      margin-bottom: 0 !important;
    }
  }
}