.profile-content {
  li {
    display: block;
    margin-bottom: 2rem;
    counter-reset: section;

    &.flexBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 400px;
      border-bottom: var(--border);
      padding: 1rem;
    }

    h4 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--color-darkblue);
      display: block;
      margin-bottom: 1rem;

      &.underline {
        text-decoration: underline;
      }

      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
      }
    }

    span {
      font-size: 1.4rem;
      font-weight: 600;
      display: block;
    }

    p {
      font-size: 1.4rem;
      color: var(--color-darkgrey);
      margin-bottom: 0.6rem;

      &.counter {
        position: relative;
        padding-left: 3rem;
        margin-bottom: 2rem;

        &::after {
          content: counter(section) " . ";
          position: absolute;
          left: 0;
          top: -0.4rem;
          counter-increment: section;
          font-size: 1.6rem;
          font-weight: 600;
        }
      }

      &.list {
        position: relative;
        padding-left: 1.5rem;
        margin-top: 1rem;

        &::after {
          content: "";
          position: absolute;
          top: 0.6rem;
          left: 0;
          height: 0.8rem;
          width: 0.8rem;
          background-color: var(--color-primary);
          border-radius: 50%;
        }
      }

      a {
        color: var(--color-darkblue);
        text-decoration: underline !important;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}

.fundview_box {
  max-width: 34rem;
  width: 100%;
  margin: 2rem auto;
  border: var(--border);
  border-radius: 0.5rem;
  padding: 2rem 0;

  h5.fund_margin {
    font-size: 2.8rem;
    color: var(--color-primary);
    font-weight: 600;
  }

  .fund_view_status {
    padding: 0 1.5rem;
    text-align: center;

    p {
      font-size: 1.4rem;
      color: var(--color-darkgrey);
    }

    h5 {
      font-size: 2.2rem;
      color: var(--color-darkblue) !important;
    }
  }

  .profile-details {
    margin-top: 2rem;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:nth-child(odd) {
        background-color: var(--color-light);
      }

      p {
        font-size: 1.5rem;

        &:first-child {
          color: var(--color-darkblue);
        }

        &:last-child {
          color: var(--color-darkgrey);
        }
      }
    }
  }
}

.fundView_note {
  display: flex;
  justify-content: center;
  max-width: 31rem;
  margin: 0 auto;
  padding: 0 1rem;
  margin-top: 3rem;

  p {
    font-size: 1.3rem;
    color: var(--color-dark);
    font-weight: 600;
  }

  span {
    font-size: 1.2rem;
    color: var(--color-darkgrey);
    margin-left: 0.4rem;
  }
}

.content {
  margin: 20px;
}
