html[data-theme="light"] {
  --color-icon: #333333;
  --color-primary: #0a53b5;
  --color-darkPrimary: #203a82;
  --color-lightPrimary: rgba(10, 83, 181, 10%);
  --color-bluegrey: #758696;
  --color-darkgrey: #6a6d78;
  --color-fulllightgrey: #f5f5f5;
  --color-lightgrey: rgba(106, 109, 120, 56%);
  --color-dark: #131722;
  --color-lightdark: rgba(19, 23, 34, 56%);
  --color-lightblue: #3f4765;
  --color-darkblue: #18214d;
  --color-blue: #203a82;
  --color-lightblue: rgba(41, 96, 255, 0.08);
  --color-extralightblue: #f5f9fc;
  --color-success: rgb(47, 128, 237);
  --color-lightsuccess: rgba(47, 128, 237, 8%);
  --color-danger: #ff0000;
  --color-lightdanger: rgba(255, 35, 31, 0.08);
  --color-white: #ffffff;
  --color-disabled: #e1e2e3;
  --color-lightwhite: rgba(255, 255, 255, 56%);
  --color-light: #f2f2f2;
  --shadow: -24px 0px 80px rgba(49, 79, 124, 0.02);
  --border: 1px solid rgba(117, 134, 150, 0.3);
  --border-color: rgba(117, 134, 150, 0.3);

  --toastify-color-success: #0a53b5;
  --toastify-color-error: #ff0000;

  body {
    background-color: var(--color-white);
  }
}

html[data-theme="dark"] {
  --color-icon: #ffffff;
  --color-primary: #094aa0;
  --color-darkPrimary: #2f80ed;
  --color-lightPrimary: rgba(10, 83, 181, 5%);
  --color-bluegrey: #fcfcfc;
  --color-darkgrey: #d1d2d2;
  --color-fulllightgrey: #212020;
  --color-lightgrey: #9f9fa0;
  --color-dark: #ffffff;
  --color-lightblue: #3f4765;
  --color-darkblue: #ffffff;
  --color-blue: #203a82;
  --color-disabled: #888989;
  --color-lightblue: rgba(41, 96, 255, 0.08);
  --color-extralightblue: #1a1c1e;
  --color-success: rgb(47, 128, 237);
  --color-lightsuccess: rgba(0, 143, 117, 0.08);
  --color-danger: #ee1e1e;
  --color-lightdanger: rgba(255, 35, 31, 0.1);
  --color-white: #141415;
  --shadow: -24px 0px 80px rgba(49, 79, 124, 0.02);
  --border: 1px solid rgb(38, 40, 42);

  body {
    background-color: #0e1012;

    .text-white {
      color: var(--color-darkblue) !important;
    }

    .MuiSelect-outlined,
    .MuiSelect-icon {
      color: #ffffff !important;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      color: white !important;
    }

    .css-i4bv87-MuiSvgIcon-root {
      color: white !important;
    }

    .premium {
      background-color: #18314d !important;
      border-color: #18214d !important;

      li {
        color: var(--color-white) !important;
      }
    }

    .contactForm-bg:after {
      background-color: #0e1012 !important;
    }
  }
}
