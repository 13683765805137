.border {
  padding: 15px;
  border: var(--border);
  margin-top: 2rem;
  color: var(--color-dark);
  border-radius: 7px;
}

.gridw {
  width: 150% !important;
}

.btnZebull {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  color: red;
  font-size: small;
  font-weight: bold;
}