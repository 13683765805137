.dematBox {
  border: var(--border);
  padding: 2rem 2rem 2rem 0;

  h2.title {
    font-size: 4rem;
    font-weight: 600;
    color: var(--color-darkblue) !important;

    @media screen and (max-width:991px) {
      font-size: 2.4rem;
    }
  }

  p.tagLine {
    font-size: 1.8rem;
    color: var(--color-darkgrey) !important;
    margin-top: 1rem;

    @media screen and (max-width:991px) {
      font-size: 1.4rem;
    }
  }

  .dematCard-item {
    margin-right: 1rem;
    width: calc(20% - 1rem);

    div.dematCard {
      border: var(--border);
      border-radius: 1rem;
      margin-top: 2rem;
      border-width: 1px;
      overflow: hidden;

      &:hover {
        border-color: var(--color-blue);
      }

      div.dematCard-img {
        height: 12rem;
        width: 100%;
        text-align: center;
        background-color: #FFFFFF;

        img {
          height: 100%;
          width: auto;
        }

        @media screen and (max-width:767px) {
          height: 14rem;
        }
      }

      div.dematCard-content {
        padding: 1rem;
        border-top: var(--border);
        height: 26rem;
        display: grid;
        align-content: space-between;

        ul {
          padding: 0;

          li {
            position: relative;
            padding-left: 2.6rem;
            font-size: 1.4rem;
            color: var(--color-darkgrey) !important;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: left;
            word-break: break-all;

            &:after {
              content: '';
              position: absolute;
              top: 1rem;
              left: 0;
              background-image: url('../../images/check.png');
              height: 1.6rem;
              width: 1.6rem;
              background-size: 100%;
            }

            span {
              font-size: 1.4rem;
              font-weight: 600;
              color: var(--color-dark) !important;
              display: contents;
              margin: 0 0.5rem;
            }
          }
        }

        button.dematCard-btn {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: var(--color-light);
          // color: var(--color-darkgrey);
          height: 4.5rem;
          border-radius: 0.5rem;
          font-size: 1.2rem;
          border: var(--border);
          background-color: var(--color-blue);
          color: var(--color-white) !important;

          // &:hover {}

          svg {
            margin-right: 1rem;
          }

          @media screen and (max-width:1199px) {
            font-size: 1.4rem;
            height: 5rem;
          }
        }

        @media screen and (max-width:1199px) {
          height: 25rem;
        }
      }
    }

    @media screen and (max-width:1399px) {
      width: calc(25% - 1rem);
    }

    @media screen and (max-width:1199px) {
      width: calc(33.33% - 1rem);
    }

    @media screen and (max-width:767px) {
      width: calc(50% - 1rem);
    }

    @media screen and (max-width:575px) {
      width: calc(100% - 1rem);
    }
  }
}