.strategybuilder_tabs {
  .MuiButtonBase-root {
    // border: 1px dashed var(--color-primary) !important;
  }
}

.strategyBox_builder {
  max-width: 1600px;
  width: 100%;
  display: block;
  margin: 0 auto;

  .createStrategy {
    padding: 1.5rem 0;
    margin: 0.6rem;

    button.createStrategy-btn {
      border: 1px dashed #6882bb;
      color: #6882bb;
      font-weight: 600;
      padding: 1rem 1.5rem;
      border-radius: 0.6rem;
      display: block;
      text-align: center;
      width: 30rem;
      max-width: 30rem;
      text-transform: capitalize;

      p {
        font-size: 1.8rem;
        color: #6882bb;
      }

      span {
        font-size: 1.5rem;
        color: var(--color-darkgrey);
      }

      svg {
        font-size: 3.2rem;
        display: block;
        margin: 0 auto;
        color: #6882bb;
      }
    }
  }

  .MuiAccordion-root {
    background-color: var(--color-white) !important;

    &::before {
      display: none;
    }
  }

  .cs-box {
    max-width: 100vw;
    border: var(--border);
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;

    .cs-th {
      display: flex;
      align-items: center;
      border-bottom: var(--border);

      .csIitem-th {
        width: 25%;
        height: 4rem;
        border-right: var(--border);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-dark);
        font-size: 1.6rem;
        background-color: var(--color-light);
        white-space: nowrap;
        padding: 0 1rem;

        &:last-child {
          border-right: none;
        }

        @media screen and (max-width: 767px) {
          min-width: 18rem;
          width: 18rem;
        }
      }
    }

    .cs-td {
      width: 100%;

      .cs-item {
        display: flex;
        align-items: center;
        border-bottom: var(--border);

        &:last-child {
          border-bottom: none;
        }

        .csIitem-td {
          width: 25%;
          height: 4rem;
          border-right: var(--border);
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color-dark);
          font-size: 1.6rem;
          white-space: nowrap;
          padding: 0 1rem;

          &:last-child {
            border-right: none;
          }

          @media screen and (max-width: 767px) {
            min-width: 18rem;
            width: 18rem;
          }
        }
      }
    }

    @media screen and (max-width: 575px) {
      justify-content: left;
    }
  }

  // Sybol Form
  .symbolCollapse {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    box-shadow: none !important;

    .collpse-header {
      &[aria-expanded="true"] {
        border-bottom: var(--border);
      }

      h5.collapse-heading {
        font-size: 1.6rem;
        font-weight: 500;
        color: var(--color-dark) !important;
        text-transform: capitalize;
      }

      svg {
        color: var(--color-dark);
      }
    }

    .symbol-form {
      border-bottom: var(--border);
      padding: 1rem 0;

      &:last-child {
        border: none;
        padding: 0;
      }
    }

    .formBox {
      .formItems {
        width: auto;
      }
    }

    .selectionDiv {
      border: none;
    }
  }
}

// createStrategy Modal
.commonModal.createStrategy {
  overflow-x: hidden;

  .MuiPaper-root {
    max-width: 450px;

    .modalHeader {
      padding: 0 !important;

      h5 {
        font-size: 1.8rem;
        padding: 0 1rem;
        color: var(--color-dark);
        font-weight: 600;
      }
    }

    .modalBody {
      padding: 6rem 2rem 2rem 2rem !important;
    }
  }
}

.entry_exit_box {
  .entry_exit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 1.3rem;
      color: var(--color-dark);
      display: flex;
      align-items: center;
      text-transform: capitalize;
      font-weight: 600;

      .northEast {
        color: var(--color-primary);
        margin-right: 0.5rem;
      }

      button.edit {
        min-width: 2.2rem;
        min-height: 2.2rem;
        font-size: 1.3rem;
      }
    }
  }

  p.entryExit_desc {
    font-size: 1.3rem;
    margin-top: 1rem;
    font-weight: 500;
    color: var(--color-darkgrey);
  }
}

.bgsb {
  background-color: var(--color-white) !important;
  padding: 1rem 1.5rem;
  border-radius: 0.6rem;
  height: 100%;
  box-shadow: 0px 3px 20px rgba(22, 43, 116, 8%);
  display: block;
}

.created_strategy_card {
  border: var(--border);
  border-radius: 0.6rem;
  padding: 2rem 1rem;
  background-color: var(--color-white);
  text-align: center;

  .stretagy_btn {
    font-size: 1.2rem;
    padding: auto 1rem;
    color: #ffffff !important;
    margin-top: 2rem;

    svg {
      font-size: 1.4rem;
      height: 1.4rem;
      width: 1.4rem;
      margin-left: 0.5rem;
    }

    &.edit_strategy {
      background: linear-gradient(135deg, #649eff 0%, #044ee3 100%);
    }
    &.delete_strategy {
      background: linear-gradient(135deg, #ff6464 0%, #e30404 100%);
    }
  }

  h4 {
    font-size: 2rem;
    color: var(--color-dark);
    margin-bottom: 0.6rem;
  }

  h5 {
    font-size: 1.5rem;
    color: var(--color-darkgrey);
    margin-bottom: 0.6rem;
  }

  h6 {
    font-size: 1.3rem;
    color: var(--color-dark);
    margin-bottom: 0.6rem;
    text-transform: uppercase;
  }

  button.formSolid-btn {
    font-size: 1.2rem;
    margin: 2rem 0 0 0;
    padding: 0.4rem 1.6rem !important;
    height: 3rem;
  }
}
