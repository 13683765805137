.login_layer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-lightPrimary);
  height: 100%;
  width: 100%;
  z-index: -1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.signForm-bg {
  height: 100%;

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .signForm-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    max-width: 1800px;
    margin: 0 auto;

    a.logoLight {
      height: 5rem;
      width: auto;
      display: block;
      border-radius: 50%;

      @media screen and (max-width: 991px) {
        height: 3rem;
      }
    }

    a.signForm-btn {
      border-radius: 5rem;
      margin: 0 0.4rem;
      font-size: 1.6rem;
      color: #ffffff;
      text-transform: capitalize;
      font-weight: 300;
      background-color: var(--color-primary);
      padding: 1rem 2.4rem;

      svg {
        margin-left: 0.6rem;
        font-size: 2rem;
      }

      @media screen and (max-width: 991px) {
        padding: 0.8rem 1.2rem;
        font-size: 1.4rem;
      }
    }

    @media screen and (max-width: 991px) {
      max-width: 100%;
      padding: 1rem 0;
    }
  }

  p.welcome-text {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-primary);
    text-align: center;
    margin-bottom: 1rem;
    // margin-top: -2rem;
  }

  .signForm-box {
    margin: 5rem auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .sign_form_left,
    .sign_form_right {
      width: 50%;
      max-width: 50%;

      img {
        border-radius: 1.5rem;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: 991px) {
        max-width: 100%;
        width: 100%;
      }
    }

    .sign_form_left {
      max-height: 80vh;
      height: 80vh;
      margin-left: 2rem;
      max-width: calc(50% - 2rem);
      width: calc(50% - 2rem);

      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    .sign_form_right {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .from-bg {
      background-color: var(--color-white);
      border-radius: 1rem;
      margin: 0 auto;
      padding: 4rem 2rem;
      max-width: 50rem;
      width: 100%;

      p.form-text {
        color: var(--color-dark);
        font-size: 2.2rem;
        font-weight: 500;
      }

      p.form-welcome {
        color: var(--color-darkgrey);
        font-size: 1.6rem;
        font-weight: 500;

        &.filled {
          color: var(--color-primary);
        }
      }

      h4.form-text {
        color: var(--color-dark);
        font-size: 2.6rem;
        margin-top: 2rem;
        font-weight: 500;
      }

      h3.form-text {
        color: var(--color-dark);
        font-size: 1.4rem;
        margin-bottom: 1rem;
        font-weight: 500;
      }

      .contactForm {
        padding: 0;
        margin-top: 2.4rem;

        .sendOTP-alert {
          padding: 1rem;
          border-radius: 0.5rem;
          border: var(--border);
          border-color: var(--color-primary);
          background-color: var(--color-lightPrimary);
          margin-bottom: 2rem;
          display: flex;
          align-items: top;

          .checkIcon {
            color: var(--color-dark);
          }

          h5 {
            font-size: 1.4rem;
            color: var(--color-dark);

            span {
              font-weight: 600;
              font-size: 1.4rem;
            }
          }

          p {
            font-size: 1.5rem;
            margin-top: 0.5rem;
            color: var(--color-dark);
          }
        }

        .countdown {
          font-size: 1.3rem;
          font-weight: 500;
          margin-top: 0.5rem;
          color: var(--color-darkgrey);
          padding-left: 1rem;

          span {
            font-size: 1.3rem;
            color: var(--color-danger);
            font-weight: 600;
          }
        }

        button.signForm-btn {
          display: flex;
          align-items: center;
          background-color: var(--color-primary);
          padding: 0.5rem 1.4rem;
          white-space: nowrap;
          border-radius: 5rem;
          font-size: 1.5rem;
          color: var(--color-white);
          font-weight: 500;
          border: none;
          outline: none;
          cursor: pointer;
          width: calc(100% - 1rem);
          margin: 0 0.5rem;
        }

        p.valide_otp {
          font-size: 1.4rem;
          color: var(--greytext);
          margin-top: 0.4rem;

          span {
            font-size: 1.4rem;
            color: #e34c4c;
            font-weight: 600;
            margin: 0 0.4rem;
          }
        }

        button.disabled-btn {
          white-space: nowrap;
          display: flex;
          align-items: center;
          background-color: #3a86e9;
          padding: 0.5rem 2rem !important;
          border-radius: 5rem;
          font-size: 1.5rem;
          color: var(--color-white);
          font-weight: 500;
          border: none;
          outline: none;
          cursor: pointer;
          width: calc(100% - 1rem);
          margin: 0 0.5rem;

          &.secound {
            border: var(--border);
            background-color: transparent;
            color: var(--color-darkgrey);

            &:hover {
              background-color: var(--color-darkblue);
              color: var(--color-white);
            }
          }

          .whatsapp-logo {
            height: 30px;
            width: 30px;
          }

          &.resendOtp {
            padding: 0.5rem;
            font-size: 1.2rem;
            width: auto;
            position: absolute;
            right: 1rem;
            top: 30px;
            background-color: transparent;
            margin-top: 10px;
          }
        }

        button.verify-btn {
          padding: 0.6rem 1rem;
          font-size: 1.3rem;
          background-color: var(--color-primary);
          color: var(--color-white);
          margin-left: 0.6rem;
        }
      }

      @media screen and (max-width: 767px) {
        padding: 1rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 1rem;
  }
}

.signContent {
  text-align: center;

  h3 {
    font-size: 2.6rem;
    color: var(--color-dark);
    font-weight: 600;
  }

  p {
    font-size: 1.4rem;
    color: var(--color-darkgrey);
    margin-top: 1rem;
  }
}

.contactForm {
  .MuiPaper-elevation {
    max-height: 30vh !important;
  }
}

.term_checkbox {
  span.MuiCheckbox-root {
    svg.MuiSvgIcon-root {
      font-size: 2.6rem;
    }

    &.Mui-checked {
      color: var(--color-primary);
    }
  }

  .MuiTypography-root {
    font-size: 1.3rem;
    color: var(--color-primary);
  }
}

// ----------- term & Condition modal
.term_condition_modal {
  div.MuiPaper-elevation {
    max-width: 80rem;
    width: 100%;
    max-height: 90vh !important;

    h2.modal_header {
      font-size: 1.6rem;
      color: var(--colortitle);
    }

    h3 {
      font-size: 1.6rem;
      color: var(--colortitle);
      // text-align: center;
      margin: 1rem;
    }

    h4 {
      font-size: 1.8rem;
      color: var(--color-dark);
      margin-top: 2rem;
    }

    p {
      font-size: 1.3rem;
      color: var(--colortitle);
      margin-top: 1.5rem;
      opacity: 0.9;

      a {
        font-size: 1.3rem;
        color: var(--color-primary);
        text-decoration: underline !important;
      }
    }

    h5 {
      font-size: 1.8rem;
      color: var(--colortitle);
      margin-top: 2rem;
    }
  }

  button.signForm-btn {
    display: flex;
    align-items: center;
    background-color: var(--color-primary);
    padding: 0.5rem 1.4rem;
    border-radius: 5rem;
    font-size: 1.3rem;
    color: var(--color-white);
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 0.5rem;

    &.secound {
      border: var(--border);
      background-color: transparent;
      color: var(--color-darkgrey);

      &:hover {
        background-color: var(--color-darkblue);
        color: var(--color-white);
      }
    }
  }
}

.accoutnHere {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;

  p {
    font-size: 1.4rem;
  }

  a.signIn_go {
    font-size: 1.4rem;
    color: var(--color-primary) !important;
    font-weight: 500;
  }
}

.resend_otp {
  position: relative;

  .actions_otp {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    z-index: 2;

    button.otp_viewHide {
      color: var(--color-primary);
      font-size: 1.4rem;
      font-weight: 600;
      background-color: transparent;
      min-width: 3rem;
    }

    button.resend_otp_btn {
      color: var(--color-primary);
      font-size: 1.4rem;
      font-weight: 600;
      background-color: transparent;
    }
  }
}