.rejectMessage {
  background-color: var(--color-dark);
  height: 3.4rem;
  width: 3.4rem;
  border-radius: 0.4rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}