@import "./Variables/color";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");

html {
  font-size: 10px !important;
}

body {
  font-size: 1.6rem !important;

  &::-webkit-scrollbar {
    display: none;
  }

  * {
    font-family: "Inter", sans-serif !important;
  }
}

a {
  color: inherit;
  text-decoration: none !important;
}

.owl-nav,
.MuiTabs-indicator,
.MuiOutlinedInput-notchedOutline {
  display: none;
}

.border-ap {
  border: var(--border);
}

.border-apb {
  border-bottom: var(--border);
}

.withOutScrollbar::-webkit-scrollbar {
  display: none;
}

.wp_button {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  display: block;
  position: fixed;
  right: 1.5rem;
  z-index: 100;
  bottom: 1.5rem;

  img {
    height: 100%;
    width: 100%;
  }
}

.no-data {
  max-height: 26rem;
  max-width: 26rem;
  margin: 1rem auto;
  text-align: center;

  img {
    height: 100%;
    width: 100%;
  }
}

p.no-dataText {
  font-size: 1.5rem;
  color: var(--color-dark);
  margin-top: 1rem;
  text-align: center;
  margin: 1rem auto;
}

// tabs
div.tabs {
  box-shadow: var(--shadow);
  width: auto;
  border-radius: 0.2rem !important;
  .MuiTabs-flexContainer {
    flex-wrap: wrap;
  }

  div.main-tab {
    div.MuiTabs-flexContainer {
      button.MuiButtonBase-root {
        border: var(--border);
        font-size: 1.3rem;
        padding: 0.2rem 1rem;
        font-weight: 500;
        color: var(--color-dark) !important;
        min-width: 10rem;
        min-height: 3.4rem;
        text-align: center;
        word-wrap: break-word;
        text-transform: capitalize;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        margin-right: 0.6rem;

        &[aria-selected="true"],
        &:hover {
          background-color: var(--color-lightPrimary);
          color: var(--color-darkPrimary) !important;
          border-color: var(--color-darkPrimary);
        }

        @media screen and (max-width: 767px) {
          min-width: auto;
          width: calc(25% - 0.6rem);
        }

        @media screen and (max-width: 575px) {
          min-width: auto;
          width: calc(33% - 0.6rem);
          padding: 0.4rem;
          font-size: 1.3rem;
        }
      }
    }
  }

  .tabBox {
    border-radius: 0.2rem !important;
    margin-top: 1.2rem;
    width: 100%;
    border: var(--border);

    .sub-tabBox {
      .MuiTabPanel-root {
        padding: 0;
      }

      div.sub-tabs {
        min-height: 3.8rem;
        width: 100%;

        button.MuiButtonBase-root {
          font-size: 1.3rem;
          padding: 0.6rem 2rem;
          font-weight: 500;
          color: var(--color-lightgrey) !important;
          background-color: var(--color-lightPrimary) !important;
          min-height: 3.4rem;
          text-align: center;
          min-width: auto;
          border-radius: 0.5rem;
          margin-right: 0.5rem;

          &[aria-selected="true"] {
            color: var(--color-darkblue) !important;
            font-weight: 600;
          }

          @media screen and (max-width: 991px) {
            padding: 1rem;
            min-width: 8rem;
            font-size: 1.4rem;
          }

          @media screen and (max-width: 767px) {
            padding: 1rem;
            min-width: auto !important;
            width: 33.33%;
            font-size: 1.4rem;
          }
        }
      }

      div.tabBox {
        margin-top: 0;
      }
    }
  }
}

// Select Menu List
.MuiMenuItem-root {
  font-size: 1.3rem !important;
  color: var(--color-dark) !important;
  background-color: var(--color-white);

  &:hover,
  &.Mui-selected {
    background-color: var(--color-lightPrimary) !important;
  }
}

p.list {
  padding-left: 1.5rem;
  position: relative;
  margin-top: 1rem;

  &::after {
    content: " ";
    position: absolute;
    top: 0.8rem;
    left: 0;
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background-color: var(--greytext);
  }
}

// table Dropdown
.selectiondiv-box {
  display: flex;
  align-items: center;
  // justify-content: end;
  flex-wrap: wrap;
  margin: 1rem 0 0 0;

  &.justify {
    justify-content: start !important;
  }

  &.flex-none {
    display: block;
  }

  .selectionDiv {
    display: inline-flex !important;
    align-items: center;
    border-radius: 0.4rem;
    text-transform: capitalize;

    &.searchFlex {
      display: flex !important;
      align-items: center;
    }

    @media screen and (max-width: 991px) {
      width: calc(50% - 2rem);
      margin: 0;
      margin-left: 0 !important;
      border: none;
      padding: 0 !important;
    }

    @media screen and (max-width: 767px) {
      width: calc(100% - 2rem);
    }

    @media screen and (max-width: 576px) {
      margin-bottom: 0;
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  &.m-rb {
    .selectionDiv {
      margin-right: 1rem !important;
      margin-bottom: 1rem !important;
    }
  }

  &.fullWidth {
    .selectionDiv,
    .dropdown-ap {
      width: 100%;
      min-width: 100%;
    }
  }

  &.small {
    .selectionDiv {
      .dropdown-ap {
        min-width: 8rem !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    justify-content: center;
    align-items: flex-end;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 1rem !important;
    width: calc(100% - 1rem);
  }
}

.MuiMenu-paper {
  background-color: var(--color-white) !important;
  border: var(--border);

  &::before {
    background-color: var(--color-white) !important;
  }
}

//  buttton
button.solidButton {
  min-height: 3.4rem;
  height: 3.4rem;
  border-radius: 0.4rem;
  color: var(--color-dark) !important;
  font-size: 1.3rem;
  text-transform: capitalize;

  &.small {
    min-height: 3rem !important;
    height: 3rem !important;
    min-width: 3rem !important;
    width: 3rem !important;

    svg {
      font-size: 1.8rem !important;
    }
  }

  &.download-btn,
  &.refresh-btn,
  &.squareOff-btn,
  &.fetchReport-btn {
    padding: 1rem;
    min-width: 3.5rem;
    width: 3.5rem;
    background-color: var(--color-blue) !important;
    color: #ffffff !important;

    svg {
      font-size: 2.4rem;
    }
  }

  &.back {
    background-color: var(--color-lightPrimary);
    color: var(--color-lightPrimary);
  }

  &.squareOff-btn {
    background-color: var(--color-danger) !important;
  }

  &.fetchReport-btn {
    background-color: var(--color-darkblue) !important;
    color: var(--color-white) !important;
  }

  &.counterBtn {
    height: 2.4rem;
    min-height: 2.4rem;
    width: 2.4rem;
    min-width: 2.4rem;
    border-radius: 50%;
    background-color: var(--color-lightgrey);
    color: var(--color-white);
    font-size: 1.8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
  }

  &.android {
    background-color: #008000 !important;
  }

  &.ios {
    background-color: #e21824 !important;
  }

  @media screen and (max-width: 900px) {
    width: calc(50% - 2rem);
    min-width: calc(50% - 2rem);
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 2rem);
    min-width: calc(100% - 2rem);
  }
}

.MuiPickersMonth-monthButton,
.MuiPickersCalendarHeader-label {
  font-size: 1.4rem !important;
}

// Date Time Picker
.MuiStack-root {
  overflow: inherit !important;
  padding-top: 0;
}

.datePicker {
  border: var(--border) !important;
  border-radius: 0.5rem;
  max-width: 17rem;
  height: 3.2rem;

  .MuiStack-root {
    padding-top: 0 !important;
  }

  .MuiOutlinedInput-root {
    height: 100%;
    width: 100%;

    .MuiSelect-select {
      height: 100%;
      width: 100%;
      padding: 0.5rem;
    }

    input.MuiInputBase-input {
      font-size: 1.4rem !important;
      padding: 0.8rem;
      color: var(--color-dark);
    }

    button.MuiButtonBase-root {
      svg.MuiSvgIcon-root {
        font-size: 2rem;
        color: var(--color-dark);
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
    width: 100%;
  }
}

.MuiDialogActions-root {
  button {
    background-color: var(--color-lightPrimary);
    font-size: 1.4rem;
  }
}

.MuiTypography-caption,
.MuiPickersDay-root,
.MuiPickersYear-yearButton {
  font-size: 1.6rem !important;
}

// Form
.formBox {
  .formItems {
    display: block;
    width: 100%;
    // margin-bottom: 1.5rem;

    // Select
    .MuiInputLabel-root {
      font-size: 1.4rem;
      color: var(--color-darkgrey);
      margin-top: -0.7rem;

      &[data-shrink="true"] {
        margin-top: 0.3rem;
        background-color: var(--color-white);
      }
    }

    label.label {
      font-size: 1.4rem;
      color: var(--color-darkgrey);
      margin-bottom: 0.5rem;
      display: block;
      font-weight: 500;
      white-space: nowrap;

      &.important {
        position: relative;

        &::after {
          content: "*";
          position: absolute;
          top: 0;
          right: auto;
          color: var(--color-danger);
          font-size: 1.4rem;
          margin-left: 0.5rem;
        }
      }

      .MuiFormControlLabel-label {
        font-size: 1.4rem;
        margin-left: 0.5rem;
        color: var(--color-darkgrey);
      }
    }

    // File Select
    .fileUpload {
      max-width: 20rem;
      border: var(--border);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.8rem;
      font-size: 1.4rem;
      padding: 0.8rem 1.5rem;
      color: var(--color-dark) !important;
      cursor: pointer;
      border-radius: 0.4rem;

      svg {
        margin-right: 1rem;
      }

      .MuiTextField-root {
        display: none;
      }
    }

    // Select
    .dropdown-ap {
      width: 100%;
      height: 3.8rem;

      .MuiFormControl-root {
        height: 100%;
      }

      .MuiInputBase-root {
        height: 100% !important;
        border: var(--border);
        font-size: 1.4rem !important;
        padding: 0.5rem 4rem 0.5rem 0.5rem;
        color: var(--color-dark);

        .MuiSelect-select {
          height: 100%;
          width: 100%;
          padding: 0.5rem;
          display: flex;
          align-items: center;
        }

        .MuiAutocomplete-endAdornment {
          .MuiButtonBase-root {
            color: var(--color-dark);
            padding: 0 !important;

            svg {
              font-size: 1.8rem;
            }
          }
        }
      }

      .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
          border: none !important;
          border: var(--border) !important;
          padding: 0;
        }
      }
    }

    // Input
    div.inputFiled {
      border-radius: 0.4rem;
      width: 100%;
      border: var(--border);
      color: var(--color-dark);

      .MuiInputBase-root {
        width: 100% !important;
        padding: 0;
        color: var(--color-dark) !important;
        height: 3.2rem;

        input,
        textarea {
          font-size: 1.4rem;
          width: 100% !important;
          outline: none;
          border-radius: 0.5rem !important;
          padding: 1rem 1.4rem;

          &::placeholder {
            font-weight: 600;
            color: var(--color-darkgrey);
          }
        }
      }
    }
  }
}

button.formSolid-btn {
  background-color: var(--color-primary) !important;
  font-size: 1.4rem;
  height: 3.4rem;
  padding: 0.6rem 1.6rem;
  color: #ffffff !important;
  font-weight: 500;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-top: 0 !important;
  min-width: auto;
  text-transform: capitalize;

  &.formOutline-btn {
    border: var(--border) !important;
    border-width: 2px !important;
    background-color: transparent !important;
    color: var(--color-darkgrey);
    margin-right: 0;
  }

  &.danger {
    background-color: var(--color-danger) !important;
  }

  &.green {
    background-color: #24a959 !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

button.download-PDF {
  background-color: var(--color-blue) !important;
  font-size: 1.4rem;
  padding: 1rem 2.4rem;
  color: #ffffff;
}

button.customize-btn {
  padding: 1rem 2rem;
  border: var(--border);
  color: var(--color-darkgrey);
  font-size: 1.4rem;
  border-radius: 0.5rem;
}

// Modal
div.commonModal {
  &.AddStrategyPrice {
    .MuiDialog-paper {
      max-width: 40rem;
    }
  }

  .modalHeader {
    border-bottom: var(--border);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: var(--color-white);

    h4 {
      font-size: 1.6rem;
      font-weight: 600;
      padding-left: 1rem;
      color: var(--color-dark);
    }

    button.closeModal {
      background-color: var(--color-danger);
      min-width: 4rem;
      min-height: 4rem;
      border-radius: 0;
      color: #ffffff;

      svg {
        height: 2rem;
        width: 2rem;
      }

      @media screen and (max-width: 991px) {
        min-height: 3.5rem;
        min-width: 3.5rem;
      }
    }
  }

  .modalBody {
    padding: 4rem 2rem 2rem 2rem;
    position: relative;
    background-color: var(--color-white) !important;

    h4 {
      font-size: 1.6rem;
      color: var(--color-dark);
      font-weight: 500;

      @media screen and (max-width: 991px) {
        font-size: 2rem;
      }
    }

    .alertIcons {
      height: 8rem;
      width: 8rem;
      margin: 2rem auto 0;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    p.alertText {
      font-size: 1.6rem;
      color: var(--color-dark);
      margin-top: 2rem;
      text-align: center;
    }

    .deployrForm {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3.6rem;

      label {
        font-size: 1.6rem;
        font-weight: 500;
        color: var(--color-darkgrey);
        margin-right: 2.6rem;

        @media screen and (max-width: 991px) {
          margin-bottom: 1rem;
          display: inline-block;
        }
      }

      .input-ap {
        input.MuiInputBase-input {
          font-size: 1.8rem !important;
        }
      }

      @media screen and (max-width: 991px) {
        display: block;
        margin-top: 2.4rem;
      }
    }

    button {
      font-size: 1.4rem;
      font-weight: 500;
      color: #ffffff;
      padding: 0.4rem 0.6rem;
      text-transform: capitalize;

      &.btn-primary {
        width: 100% !important;
        background-color: var(--color-primary);
      }

      &.btn-danger {
        width: 100% !important;
        background-color: var(--color-danger);
      }
    }
  }
}

.color {
  color: red;
  font-size: smaller;
}

.footer {
  height: 4.5rem;
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-align: center;

  p {
    font-size: 1.3rem;
    color: var(--color-darkgrey);
  }
}

// Mui Select with checkbox
.select_check {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  .dropdown-ap {
    border: var(--border);
    border-radius: 0.4rem !important;
    height: 3.4rem;

    .MuiSelect-multiple {
      padding: 0.8rem;
      font-size: 1.3rem;
    }

    .MuiSelect-nativeInput {
      height: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
}

.MuiMenuItem-root {
  .MuiListItemText-root {
    .MuiTypography-root {
      font-size: 1.4rem !important;
    }
  }

  .MuiCheckbox-root {
    padding: 0.6rem;
    color: var(--color-dark);

    .MuiSvgIcon-root {
      height: 2rem;
      width: 2rem;
    }
  }
}

.textarea-1 {
  font-size: 1.4rem !important;
  padding: 1rem 1rem 1rem 1rem !important;
  color: var(--color-dark);
  width: 100%;
  height: 1.4rem;
  max-width: 16rem;
  border: var(--border);
  border-radius: 0.5rem;

  &::placeholder {
    color: var(--color-dark) !important;
    font-weight: 600;
  }

  &.Mui-disabled {
    -webkit-text-fill-color: var(--color-darkgrey);
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
    width: 100%;
  }
}

.term_checkbox {
  .MuiSwitch-root {
    .MuiButtonBase-root {
      &.Mui-checked {
        .MuiSwitch-thumb {
          color: var(--color-white);
        }

        & + .MuiSwitch-track {
          background-color: var(--color-white);
        }
      }
    }
  }

  .MuiTypography-root {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-primary);
  }
}

.segmentSearch {
  height: auto !important;
  padding: 0 !important;

  .MuiFormControl-root {
    height: auto;
  }

  .MuiInputBase-root {
    padding: 0 !important;
    border-color: var(--color-primary) !important;

    .MuiInputBase-input {
      top: 0;
      padding: 0.7rem !important;
      width: 100% !important;
      height: auto !important;
    }
  }

  .MuiAutocomplete-endAdornment {
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 1199px) {
    padding: 0 !important;
  }
}

.adminCtradeBox {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  padding: 0 1rem;
  width: calc(100% - 1rem);

  .adminCtradeBoxItem {
    width: calc(25% - 2rem);
    padding: 0 1rem;
    margin-bottom: 1rem;

    &.flex {
      display: flex;
      align-items: center;
      justify-content: center;

      button.download-btn {
        height: 3.2rem;
        min-height: 3.2rem;
        min-width: 3.2rem;
        width: 3.2rem;
        padding: 1rem;
      }
    }

    .selectionDiv {
      width: 100%;
      margin: 0 !important;
      padding: 0.7rem 0.3rem;

      .dropdown-ap {
        width: 100%;
      }
    }

    @media screen and (max-width: 900px) {
      width: calc(33.33% - 2.2rem);
      margin-bottom: 0.6rem;
    }

    @media screen and (max-width: 767px) {
      width: calc(50% - 2.2rem);
      margin-bottom: 0.6rem;
    }

    @media screen and (max-width: 576px) {
      width: calc(100% - 2.2rem);
      margin-bottom: 0.6rem;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0;
    width: 100%;
  }
}
