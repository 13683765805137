.strategyCard {
  border: var(--border);
  border-radius: 0.6rem;
  padding: 2rem 1.5rem;

  &:hover {
    border-color: var(--color-primary);
  }

  .strategyCard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .strategyCard-title {
      p.title {
        font-size: 1.6rem;
        color: var(--color-dark);
        font-weight: 600;
      }

      p.days {
        font-size: 1.2rem;
        color: var(--color-darkgrey);

        span {
          color: var(--color-primary);
          margin-left: 0.5rem;
          font-size: 1.2rem;
        }
      }
    }

    div.dayGainLoss {
      display: flex;
      align-items: center;

      span {
        font-size: 1.2rem;
        color: var(--color-darkgrey);
        display: block;
        margin-right: 0.4rem;
      }

      .status {
        padding: 0.5rem 0.8rem;
        border-radius: 0.4rem;
        font-size: 1.2rem;

        &.up {
          background-color: #24a959;
          color: #ffffff;
        }

        &.down {
          background-color: var(--color-lightdanger);
          border: 1px solid var(--color-danger);
          color: var(--color-danger);
        }

        &.down-danger {
          color: #ffffff;
          // border: 1px solid var(--color-danger);
          background-color: var(--color-danger);
        }
      }
    }

    button.copyShare-link {
      background-color: transparent;
      height: 3rem;
      width: 3rem;
      min-width: 3rem;
      color: var(--color-darkgrey);

      svg {
        font-size: 2rem;
      }
    }
  }

  .strategyCard-body {
    .read_more {
      font-size: 1.4rem;
      color: var(--color-primary);
      margin: 1rem 0 0 0;
    }

    .trade_progress {
      display: flex;
      align-items: center;
      justify-content: left;
      flex-wrap: wrap;

      .trade_progress_item {
        border-right: var(--border);
        padding: 0.8rem;
        margin-right: 0.5rem;

        &:last-child {
          border-right: none;
        }

        p {
          font-size: 1.4rem;
          color: var(--color-darkgrey);
          display: flex;
          align-items: center;
          opacity: 0.8;
        }

        h4 {
          font-size: 1.6rem;
          color: var(--color-dark);
        }
      }
    }

    .selectiondiv-box {
      .selectionDiv,
      .dropdown-ap {
        width: calc(100% - 1rem);
        margin: 0 !important;
        margin-right: 0 !important;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .strategyCard-item {
        padding: 0.5rem;
        width: calc(33.33% - 1rem);
        overflow: hidden;

        .simpleText {
          display: block;
          align-items: center;
          justify-content: left;
          padding: 0.6rem;
          border: var(--border);
          border-radius: 0.4rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: calc(100% - 1rem);
          margin-right: 1rem;
          font-size: 1.3rem;
          margin-top: 0.5rem;
          opacity: 0.6;
          background-color: var(--color-light);
        }

        &.small {
          width: calc(25% - 1rem);
        }

        .selectionDiv {
          margin-right: 0 !important;
        }

        @media screen and (max-width: 767px) {
          width: calc(50% - 1rem);
        }
      }
    }

    .profilt_loss {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;

      label {
        font-size: 1.2rem;
        color: var(--color-dark);
        margin-right: 0.5rem;
        text-transform: capitalize;
        font-weight: 600;
      }

      p {
        font-size: 1.2rem;
        color: #ffffff;
        padding: 0.2rem 0.6rem;
        border-radius: 0.4rem;

        &.loss {
          background-color: var(--color-danger);
        }

        &.profit {
          background-color: #24a959;
        }
      }
    }

    .All_total {
      margin-top: 0.4rem;
      padding: 0.5rem;
      border: var(--border);

      h6 {
        font-size: 1.1rem;
        text-transform: capitalize;
        color: var(--color-dark);
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        font-size: 1.1rem;
        color: var(--color-darkgrey);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .strategy_action {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .stretagy_btn {
      font-size: 1rem;
      padding: 0.4rem 1rem;
      color: #ffffff !important;
      margin-left: 0.3rem;

      svg {
        font-size: 1.4rem;
        height: 1.4rem;
        width: 1.4rem;
        margin-left: 0.5rem;
      }

      &.trailing {
        background-color: #044ee3;
      }

      &.square_off {
        background-color: #ff0000;
      }

      &.edit_strategy {
        background: linear-gradient(135deg, #649eff 0%, #044ee3 100%);
      }

      @media screen and (max-width: 767px) {
        width: calc(50% - 0.6rem);
        margin-top: 0.6rem;
      }
    }

    .triling-stoploss {
      background-color: #044ee3;
      color: #fff !important;
      padding: 0.4rem !important;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .strategyCard-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.6rem;
    flex-wrap: wrap;

    .strategyCard-btn {
      font-size: 1.3rem;
      padding: 0.4rem 1.5rem;
      margin: 0 0.5rem;
      text-transform: capitalize;

      &.bg-solid {
        background-color: var(--color-primary);
        color: #ffffff;
      }
    }

    &.sc_fb {
      border-top: var(--border);
      padding-top: 0.5rem;

      p {
        font-size: 1.2rem;
        color: var(--color-darkgrey);

        span {
          font-size: 1.2rem;
          font-weight: 600;
          color: var(--color-dark);
        }
      }
    }

    @media screen and (max-width: 575px) {
      justify-content: center;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 1rem;
  }
}

.strategy_badges {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  margin: 1rem 0;

  p.badges {
    padding: 0.2rem 1rem;
    border-radius: 5rem;
    background-color: var(--color-lightPrimary);
    color: var(--color-dark);
    font-size: 1.2rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
  }
}

.deployModal {
  .MuiPaper-elevation {
    max-width: 600px !important;

    .inputFiled {
      width: 100%;
    }
  }
}

// =============== Read More Passive Income
.passive_income {
  padding: 1rem;
  border: var(--border);
  border-radius: 0.5rem;

  p.created {
    font-size: 1.3rem;
    color: var(--color-darkgrey);
    margin-bottom: 1rem;
  }

  .strategy_name_box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .strategy_name {
      margin-bottom: 1rem;

      h5 {
        font-size: 2.2rem;
        color: var(--color-dark);
        font-weight: 600;
      }

      .by_from {
        display: flex;
        align-items: center;
        justify-content: left;
        font-size: 1.3rem;

        p {
          font-size: 1.3rem;
          color: var(--color-dark);
        }
        label {
          font-size: 1.4rem;
          font-weight: 600;
          color: var(--color-darkgrey);
        }

        span.by_name {
          font-size: 1.3rem;
          color: var(--color-darkgrey);
          margin: 0 0.3rem;
        }

        span.icon {
          padding: 0.4rem;
          height: 1.8rem;
          min-width: 2rem;
          border-radius: 50%;
          color: var(--color-white);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.5rem;

          svg {
            height: 1.6rem;
            width: 1.6rem;
          }

          &.mail {
            background-color: var(--color-primary);
          }

          &.bank {
            background-color: #f75723 !important;
          }

          &.SD {
            background-color: var(--color-lightPrimary);
            color: var(--color-dark);
            font-size: 1.3rem;
          }
        }
      }
    }

    .deployed {
      background-color: var(--color-primary);
      padding: 0.7rem 1rem;
      border-radius: 0.4rem;
      font-size: 1.4rem;
      color: #ffffff;
      margin-left: 0.6rem;

      &.undeployed {
        background-color: var(--color-danger) !important;
      }
    }

    .favorite {
      background-color: transparent !important;
      min-height: 4rem;
      min-width: 4rem;
      width: 4rem;

      svg {
        font-size: 2.4rem !important;
        color: var(--color-primary) !important;
      }
    }

    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  .strategy_desc {
    margin-top: 2rem;

    h4 {
      font-size: 2rem;
      color: var(--color-dark);
      font-weight: 600;
    }

    h5 {
      font-size: 1.6rem;
      color: var(--color-dark);
      font-weight: 600;
    }

    h3 {
      font-size: 2rem;
      color: var(--color-primary);
      font-weight: 600;
    }

    p {
      font-size: 1.3rem;
      color: var(--color-darkgrey);

      a {
        font-size: 1.3rem;
        color: var(--color-darkgrey);
        font-weight: 600;
        text-decoration: underline !important;
      }
    }
  }
}

.Trailing_modal {
  .MuiPaper-elevation {
    max-width: 400px !important;
  }
}

// =========== On Off Radio
.onoff_toggle {
  border: var(--border);
  border-radius: 0.6rem;
  height: 3rem !important;
  // margin-left: 0.5rem;
  margin-top: 0.4rem;

  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 0;
    position: relative;

    .MuiRadio-root {
      height: 3rem;
      width: 4.2rem;
      border-radius: 0.5rem;

      span {
        display: none;
      }
    }

    &:first-child {
      .MuiRadio-root.Mui-checked {
        background-color: #24a959;

        & ~ .MuiTypography-root {
          color: var(--color-white);
        }
      }
    }

    &:last-child {
      .MuiRadio-root.Mui-checked {
        background-color: var(--color-danger);

        & ~ .MuiTypography-root {
          color: var(--color-white);
        }
      }
    }

    .MuiTypography-root {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.3rem;
    }
  }
}

.backto_home {
  color: var(--color-dark);
  font-size: 1.4rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  background-color: var(--color-lightPrimary);
  padding: 0.4rem;
  border-radius: 0.4rem;

  svg {
    margin-right: 0.5rem;
  }
}

.strategy_list_box {
  width: 30rem;
  // width: calc(100% - 3rem);
  padding: 1rem;
  border: var(--border);
  border-radius: 0.6rem;
  margin-right: 3rem;

  .strategy_list {
    height: 100%;
    margin-top: 0.6rem;

    h4 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 2rem 0 1rem 0;
    }

    .list {
      padding: 0;
      padding-bottom: 0.8rem;
      border-bottom: var(--border);

      li {
        font-size: 1.4rem;
        color: var(--color-darkgrey);
        padding: 0.3rem 0.8rem;
        cursor: pointer;
        opacity: 0.8;

        &.active {
          // color: var(--color-primary);
          color: var(--color-darkPrimary);
          opacity: 1;
          font-weight: 600;
        }
      }
    }

    @media screen and (max-width: 991px) {
      max-height: calc(100vh - 20rem);
      padding: 0 0 2rem 0;
    }
  }

  @media screen and (max-width: 991px) {
    position: fixed;
    left: -100%;
    top: 5rem;
    background-color: var(--color-white);
    z-index: 5;
  }
}

.filter_strategy {
  display: none !important;

  @media screen and (max-width: 991px) {
    display: flex !important;
  }
}

.MuiPagination-ul {
  li {
    button.MuiPaginationItem-root {
      font-size: 1.6rem;
      color: var(--color-dark);

      &.Mui-selected {
        background-color: var(--color-darkblue);
        color: var(--color-white);
      }

      svg {
        font-size: 2rem;
        color: var(--color-dark);
      }
    }
  }
}
