.userview {
  padding: 1.6rem 2.4rem;
  border: var(--border);

  .userviewActions {
    display: flex;
    align-items: center;

    @media screen and (max-width:900px) {
      display: block;
    }
  }

  @media screen and (max-width:767px) {
    padding: 1rem;
  }
}