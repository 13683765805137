.newAlert {
  display: flex;
  align-items: center;
  justify-content: end;

  .newAlert-item {
    p {
      color: var(--color-darkgrey);
      font-size: 1.4rem;
      font-weight: 600;
    }

    button.refresh-btn {
      height: 4rem;
      min-height: 4rem;
      width: 4rem !important;
      min-width: 4rem !important;
      color: var(--color-darkgrey) !important;
      margin-left: 0.8rem;
    }
  }
}

.searchData {
  margin: 0 !important;
}

.alertStyle {
  margin: 1.5rem 0;

  label.label {
    color: var(--color-darkgrey) !important;
    opacity: 0.8;
    margin-bottom: 1rem;
    display: block;
  }

  label .MuiFormControlLabel-label {
    color: var(--color-dark) !important;
  }
}

.searchSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem !important;
  margin: 1rem;
  cursor: pointer;

  .exchangeName {
    h4 {
      font-size: 1.4rem !important;
      color: var(--color-dark) !important;
      text-transform: uppercase;
      font-weight: 500;
    }

    p {
      font-size: 1.2rem;
      color: var(--color-darkgrey) !important;
      text-transform: uppercase;
      opacity: 0.8;
    }
  }

  h5.rate {
    font-size: 1.2rem;
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 1rem;
      font-size: 1.2rem;
      height: 2rem;
      width: 2rem;
    }

    &.up {
      color: #0071f3 !important;

      svg {
        color: #0071f3 !important;
      }
    }

    &.down {
      color: var(--color-danger) !important;

      svg {
        color: var(--color-danger) !important;
      }
    }
  }
}

.subMain-tab {
  button.MuiTab-root {
    width: calc(33.33% - 0.8rem);
  }
}

.up {
  color: #24a959;
  background-color: rgba(38, 221, 128, 0.24);
}

.down {
  color: var(--color-danger);
  background-color: var(--color-lightdanger);
}

.delete_mw {
  min-height: 3.5rem !important;
  min-width: 3.5rem !important;
  border-radius: 0.4rem;
  background-color: var(--color-danger) !important;
  color: #ffffff !important;
  margin-right: 0.5rem !important;
  padding: 0.6rem;

  svg {
    font-size: 2rem;
  }
}

.static {
  color: var(--color-dark);
  background-color: var(--color-disabled);
  border-radius: 4px;
  padding: 0 2px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.autocomplete {
  width: 100% !important;
  border: 1px solid rgba(117, 134, 150, 0.3) !important;
  border-radius: 5px;
  font-size: 10px;
}
