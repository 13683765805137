div.livePrice-list {
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  margin-bottom: 2rem;

  div.livePrice-item {
    border: var(--border);
    border-radius: 0.3rem;
    height: 5rem;
    min-width: 16.3rem;
    max-width: 16.3rem;
    width: 16.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left-width: 2px;
    margin-right: 1.5rem;

    &.low {
      border-left-color: var(--color-danger);
    }

    &.high {
      border-left-color: #26de81;
    }

    div.price {
      padding: 0.5rem 0.6rem;
      width: 100%;
      white-space: nowrap;

      h6 {
        font-size: 1.3rem;
        text-transform: uppercase !important;
        color: var(--color-dark);
        font-weight: 600;
        margin-bottom: 0.3rem;
      }

      p {
        font-size: 1.2rem;
        color: var(--color-dark);
      }

      small {
        font-size: 1rem;
        color: var(--color-dark);
        display: flex;
        align-items: center;
        justify-content: left;

        span {
          padding: 0rem 0.5rem;
          font-size: 1rem;
          border-radius: 0.5rem;
          margin-left: 0.5rem;
          font-weight: 600;
          text-shadow: none;

          &.up {
            color: #24a959;
            background-color: rgba(38, 221, 128, 0.24);
          }

          &.down {
            color: var(--color-danger);
            background-color: var(--color-lightdanger);
          }
          &.static {
            color: var(--color-dark);
            background-color: var(--color-disabled);
          }

          svg {
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
}
