Button.squerOff {
  height: 3.4rem;
  min-width: 3.4rem;
  max-width: 3.4rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-lightdanger);
  color: var(--color-danger) !important;
  margin: 0 auto;

  svg {
    font-size: 1.8rem;
  }
}

.squareOff {
  .MuiPaper-elevation {
    max-width: 300px !important;
  }
}

label.label {
  font-size: 1.4rem;
  color: var(--color-dark);
  margin-right: 1rem;
}

.tradeType-toggle {
  padding: 0.2rem 0.2rem;
  border-radius: 5rem !important;
  border: var(--border);
  // margin-top: 0.5rem;

  button {
    font-size: 1.3rem !important;
    border: none;
    color: var(--color-dark) !important;
    border-radius: 5rem !important;
    padding: 0.2rem 2.4rem;
    font-weight: 500 !important;
    height: 3rem;
    min-height: 3rem;

    &.Mui-selected {
      border-color: var(--color-primary);
      background-color: var(--color-darkPrimary) !important;
      color: var(--color-white) !important;
    }

    // &:hover {
    //   background-color: var(--color-light);
    //   color: var(--color-primary) !important;
    // }
  }
}