.inputFields {
  border-radius: 0.6rem;
  position: relative;
  display: inline-block;
  width: 100%;

  &.space-y {
    margin-bottom: 1rem;
  }

  &.flex {
    display: flex;
    align-items: center;
    width: auto;
  }

  label.label {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    display: block;
    color: var(--color-darkgrey);

    &.important {
      position: relative;

      &::after {
        content: "*";
        position: absolute;
        top: 0;
        right: auto;
        color: var(--color-danger);
        font-size: 1.4rem;
        margin-left: 0.5rem;
      }
    }
  }

  // Radio button
  .MuiFormControlLabel-root {
    .MuiRadio-root {
      height: 3rem;
      width: 3rem;
      color: var(--color-lightgrey);

      .MuiSvgIcon-root {
        height: 2.6rem;
        width: 2.6rem;
      }

      &.Mui-checked {
        color: var(--color-primary) !important;
      }
    }

    span.MuiTypography-root {
      font-size: 1.4rem;
      color: var(--color-dark);
    }
  }

  .MuiInputBase-root,
  .MuiFormControl-root {
    width: 100%;
    height: 100%;
    padding: 0;

    input,
    textarea {
      font-size: 1.4rem !important;
      padding: 1rem 1rem 1rem 4rem !important;
      color: var(--color-dark);
      width: 100%;
      height: 1.4rem;
      max-width: 16rem;
      border: var(--border);
      border-radius: 0.5rem;

      &::placeholder {
        color: var(--color-dark) !important;
        font-weight: 600;
      }

      &.Mui-disabled {
        -webkit-text-fill-color: var(--color-darkgrey);
      }

      @media screen and (max-width: 991px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &.filled {
    .MuiInputBase-root,
    .MuiFormControl-root {
      input,
      textarea {
        background-color: #f2f2f2;
      }
    }
  }

  &.space {
    input,
    textarea {
      padding: 1rem !important;

      @media screen and (max-width: 991px) {
        padding: 0.8rem !important;
      }
    }
  }

  &.fullWidth {
    input,
    textarea {
      width: 100%;
      max-width: 100%;
      // height: 100%;
    }
  }

  .searchIcon {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 2.4rem;
    opacity: 0.7;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    margin-bottom: 0;
    width: 100%;
  }
}
