header {
  display: block;
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: var(--color-white);
  border-bottom: var(--border);

  a.brokerStatus-green {
    background-color: #008000;
    padding: 0.5rem 1.4rem;
    border-radius: 0.8rem;
    color: var(--color-white) !important;
    display: inline-block;
    font-size: 1.4rem;
    margin-left: 5rem;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  a.ap-logo {
    height: 4.5rem;
    width: auto;
    display: block;
    border-radius: 0.5rem;
    padding: 0.4rem;
    margin: 0.4rem 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    @media screen and (max-width: 991px) {
      height: 3rem;
      width: auto;
      padding: 0;
    }
  }

  .MuiDivider-root {
    border-left: var(--border);
    border-right-width: 0;
    height: 3.6rem;
    margin: auto 0;
  }

  a.brokerStatus {
    background-color: var(--color-danger);
    padding: 0.4rem 1rem;
    border-radius: 0.8rem;
    color: #ffffff !important;
    display: inline-block;
    font-size: 1.4rem;
    margin-left: 3rem;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  div.dayGainLoss {
    margin: 0 1.5rem;

    span {
      font-size: 1.2rem;
      color: var(--color-dark);
      font-weight: 600;
      margin-bottom: 0.3rem !important;
      display: block;
    }

    .status {
      padding: 0.4rem 0.8rem;
      border-radius: 0.4rem;
      margin-right: 1rem;
      font-size: 1.2rem;

      &:last-child {
        margin-right: 0;
      }

      &.up {
        background-color: #24a959;
        color: #ffffff;
      }

      &.down {
        background-color: var(--color-danger);
        color: #ffffff;
      }

      svg {
        margin-left: 1rem;
      }
    }

    @media screen and (max-width: 1199px) {
      margin-right: 1rem;
    }

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  button.headerButtons,
  a.headerButtons {
    min-height: auto;
    min-width: auto;
    height: 5rem;
    width: 4rem;
    padding: 1rem;
    text-align: center;
    font-size: 2.6rem !important;
    color: var(--color-dark);
    margin: 0 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.humburgar {
      display: none;

      @media screen and (max-width: 991px) {
        display: flex;
      }
    }

    svg {
      font-size: 2.2rem;

      path {
        fill: var(--color-dark) !important;
      }
    }

    @media screen and (max-width: 991px) {
      width: 3.5rem;
      padding: 0.5rem;
    }
  }

  a.headerButtons {
    min-width: 2.6rem;
    width: 2.6rem;
    height: 3rem;
  }

  .humburgarDivider {
    display: none;

    @media screen and (max-width: 991px) {
      display: block;
    }
  }

  .userMenu {
    font-size: 1.4rem;
    color: var(--color-dark);
    min-width: 5rem;

    .userName {
      font-size: 1.4rem;
      font-weight: 600;
      margin-right: 1.5rem;
      text-transform: capitalize;
    }

    .userDrp {
      height: 3.2rem;
      width: 3.2rem;
    }
  }
}

.userMenu {
  display: inline-flex;
  align-items: center;
  justify-content: left;

  .userName {
    height: 3.4rem;
    width: 3.4rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    margin-right: 0.5rem;
    background-color: var(--color-lightPrimary);
    color: var(--color-primary);
  }

  .userFullname {
    display: block !important;
    padding: 1rem;
    width: 50%;

    h5 {
      font-size: 1.4rem;
      color: var(--color-dark);
      display: block;
      width: 100%;
      text-transform: capitalize;
    }

    p {
      font-size: 1.2rem;
      color: var(--color-darkgrey);
      display: block;
      width: 100%;
    }
  }
}

.navMenu-item {
  width: 100%;
  padding: 0.6rem 1.5rem !important;
  font-size: 1.4rem;

  &.userName {
    padding: 1.5rem;
  }
}

// Notification
.notifyModal {
  min-width: 250px;
  overflow-y: hidden;

  .notifyHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: var(--border);
    background-color: var(--color-light);

    h3 {
      font-size: 1.8rem;
      color: var(--color-dark);
      font-weight: 600;
    }

    span {
      height: 3.5rem;
      width: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-darkgrey);
    }

    .clear {
      min-width: 3rem;
      width: 3rem;
      height: 3rem;
      color: var(--color-primary) !important;
      font-size: 1.1rem;
      margin-right: 0.6rem;
    }
  }
}

.notifyBody {
  overflow-y: auto;
  max-height: 35rem;

  .NotifyBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;

    .notifyCheck {
      height: 2.4rem;
      width: 2.4rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .notiFy {
      margin-left: 1rem;
      width: calc(100% - 3rem);

      h4 {
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--color-dark);
        margin-bottom: 0.4rem;

        span {
          font-size: 1.3rem;
          font-weight: 500;
          margin-right: 0.4rem;

          &.buy {
            color: #008000;
          }

          &.sell {
            color: var(--color-danger);
          }
        }
      }

      p {
        font-size: 1.2rem;
        color: var(--color-darkgrey);
        opacity: 1;

        &:last-child {
          font-size: 1.1rem;
          color: var(--color-darkgrey);
          opacity: 0.8;
        }
      }
    }
  }
}

.unLayer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000, $alpha: 0.5);
  z-index: 53;
}
