.stretagy_builder_box {
  padding: 0 0;

  .MuiStepper-root {
    margin-top: 3rem;

    .MuiStep-root {
      &.Mui-completed {
        transition: 2s;

        .MuiStepConnector-root {
          .MuiStepConnector-line {
            border-color: var(--color-primary) !important;
            transition: 2s;
          }
        }
      }
    }

    .MuiStepLabel-root {
      .MuiStepLabel-iconContainer {
        height: 3rem;
        width: 3rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 50%;

        &.Mui-active,
        &.Mui-completed {
          transition: 2s;

          svg {
            color: var(--color-primary) !important;

            .MuiStepIcon-text {
              color: var(--color-white);
              fill: var(--color-white);
            }
          }
        }

        svg {
          height: 100%;
          width: 100%;
          color: var(--color-white);
          background-color: #FFFFFF;
          border-radius: 50%;

          .MuiStepIcon-text {
            font-size: 1.4rem;
            color: var(--color-dark);
            fill: var(--color-dark);
          }
        }
      }
    }

    .MuiStepLabel-label {
      font-size: 1.4rem;
      color: var(--color-dark);
    }
  }


  // Stepper button
  .Stepper-button {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 3rem;

    .back_step {
      background-color: var(--color-lightPrimary);
      color: var(--color-primary);
      font-size: 1.4rem;
      padding: 0.5rem 1rem;
      display: block;
      border-radius: 0.4rem;
    }

    .next_step {
      background-color: var(--color-primary);
      color: var(--color-white);
      font-size: 1.4rem;
      margin-left: 0.8rem;
      padding: 0.6rem 1rem;
      border-radius: 0.4rem;
      display: block;
    }
  }
}


.MuiStepConnector-root {
  .MuiStepConnector-line {
    border-top-width: 5px;
    border-radius: 1rem;
    // transition: linear 2s !important;
    opacity: .5;
    display: none;
  }

  &.Mui-active {
    .MuiStepConnector-line {
      border-color: var(--color-primary);
    }
  }
}

// =========== Order Box
.strategy_box {
  margin-top: 2rem;

  h4.title_positions {
    font-size: 1.8rem;
    color: var(--color-dark);
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .order_box {
    border-radius: 0.6rem;
    padding: 2rem;
    box-shadow: 0px 3px 20px rgba(22, 43, 116, 7%);
    background-color: var(--color-white) !important;

    &.shn {
      box-shadow: none;
    }

    @media screen and (max-width:991px) {
      padding: 0.5rem;
    }
  }

  .order_type_switch {
    display: block !important;

    label.label {
      display: block;
      margin-bottom: 0.6rem;
    }

    .tradeType-toggle {
      border-radius: 0.5rem !important;
      padding: 0;
      border: none;
      flex-wrap: wrap;

      button.MuiToggleButton-root {
        color: var(--color-dark) !important;
        border-radius: 0.5rem !important;
        background-color: rgba(104, 130, 187, 16%);
        padding: 0.5rem 1.2rem;
        margin: 0 0.2rem;
        border: none !important;

        svg {
          height: 2rem;
          width: 2rem;
        }

        &.sell {
          background-color: rgba(235, 29, 84, 25%);
          color: var(--color-danger) !important;
        }

        &.buy {
          background-color: rgba(36, 169, 89, 15%);
          color: #24a959 !important;
        }

        &.both {
          color: var(--color-primary) !important;
        }
      }

      button.Mui-selected {
        background-color: #6882bb !important;
        color: #FFFFFF !important;

        &.sell {
          background-color: var(--color-danger) !important;
          color: #ffffff !important;
        }

        &.buy {
          background-color: #24a959 !important;
          color: #ffffff !important;
        }

        &.both {
          background-color: var(--color-primary) !important;
          color: #ffffff !important;
        }
      }

    }
  }


  .entry_condition_box {
    width: auto;
    height: 100%;
    position: relative;

    .entry_condition_item {
      display: flex;
      align-items: center;
      justify-content: left;
      flex-wrap: wrap;

      .entrys_condition {
        font-size: 1.4rem;
        color: var(--color-primary);
      }
    }

    .entry_condition_actions {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 1rem;
      right: 1.5rem;

      button {
        font-size: 1.2rem;
        padding: 0.5rem;
        text-transform: capitalize;

        svg {
          margin-right: 0.4rem;
        }

        &.btn_default {
          color: var(--color-dark);
          opacity: 0.6;
        }

        &.btn_delete {
          color: var(--color-danger);
        }
      }
    }

  }

  .entry_time {
    background-color: var(--color-white);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 1.5rem;
    justify-content: space-between;
  }
}



// review_deploy_box
.review_deploy_box {
  h4.heading {
    font-size: 2rem;
    color: var(--color-dark);
  }

  .overview_mainbox {
    display: flex;
    align-items: center;
    justify-content: left;
    // border: var(--border);
    border-radius: 0.5rem;

    .overview_item {
      width: calc(14% - 1rem);
      padding: 1rem;
      margin-right: 1rem;
      border-right: var(--border);
      background-color: rgba($color: #999999, $alpha: 0.08);
      border-radius: 0.5rem;
      margin-top: 1rem;

      h6 {
        font-size: 1.5rem;
        color: var(--color-dark);

        button {
          min-width: 3rem;
          height: 3rem;
          color: var(--color-darkgrey);
        }
      }

      p {
        font-size: 1.3rem;
        color: var(--color-darkgrey);

        &.down {
          color: var(--color-danger);
        }

        &.up {
          color: #24a959;
        }
      }
    }
  }

  .report_box {
    border: var(--border);
    border-top: none;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.save_deploy_modal {

  .save_deploy_box {
    margin: 1rem 0;

    h4 {
      font-size: 2rem !important;
      color: var(--color-dark) !important;
      font-weight: 600 !important;
    }

    p {
      font-size: 1.3rem !important;
      color: var(--color-darkgrey);
      margin: 1rem 0;
    }
  }
}




.MultieValue {
  .MuiInputBase-root {
    position: relative;
    padding-right: 7rem !important;
  }

  .autoComplete-box {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    right: 5rem;
    display: flex;
    align-items: center;
    justify-content: right;
    color: var(--color-blue);
    font-size: 1.3rem;

    svg {
      margin-right: 0.5rem;
      font-size: 1.2rem;
      height: 1.8rem;
      width: 1.8rem;
    }
  }
}

.progress-bar-container {
  width: 100%;
  height: 6px;
  border-radius: 2rem;
  background-color: #EAEDF4;
  position: relative;
  position: absolute;
  top: 20%;
  z-index: -5;

  .progress-bar {
    height: 100%;
    width: 0;
    background-color: var(--color-primary);
    border-radius: 2rem;
    z-index: -6;
  }
}