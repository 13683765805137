.activeTrade-box {
  border: var(--border);

  .dataProcess-date {
    padding: 1.6rem 2.4rem;
    color: var(--color-dark);
    font-size: 1.4rem;
    display: flex;
    align-items: center;

    span {
      color: var(--color-blue);
      font-weight: 600;
      font-size: 1.4rem;
      margin-left: 0.5rem;
    }
  }

  .tradeStatus {
    display: flex;
    align-items: center;
    background-color: var(--color-extralightblue);
    padding: 1.6rem 0;
    border-bottom: var(--border);
    margin-bottom: 1.6rem;

    .tradeStatus-item {
      width: 20%;
      padding: 1.8rem 2.4rem;
      border-right: var(--border);

      &:last-child {
        border-right: none;
      }

      p {
        font-size: 1.4rem;
        color: var(--color-dark);
        margin-bottom: 1.8rem;
      }

      h3 {
        font-size: 2.4rem;
        font-weight: 600;
        color: var(--color-dark);
      }

      @media screen and (max-width:1199px) {
        width: calc(33.33% - 5rem);
        margin-bottom: 1rem;

        &:nth-child(3) {
          border-right: none;
        }
      }

      @media screen and (max-width:767px) {
        width: 100%;
        border-right: none;
        border-bottom: var(--border);
      }
    }

    @media screen and (max-width:1199px) {
      flex-wrap: wrap;
      border-bottom: none;
      margin-bottom: 0;
    }
  }


  .highlow-day {
    .highlow-day-item {
      span {
        background-color: var(--border-color);
        padding: 0.8rem 1.8rem;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        color: var(--color-darkgrey);
        margin-bottom: 1.6rem;
        display: inline-block;
      }

      .high-rate,
      .low-rate {
        display: flex;
        align-items: center;
        margin-bottom: 1.6rem;

        p {
          height: 2.6rem;
          width: 2.6rem;
          font-size: 1.4rem;
          border-radius: 0.5rem;
          color: var(--color-white);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;

          &.high {
            background-color: var(--color-primary);
            color: var(--color-dark);
          }

          &.low {
            background-color: var(--color-danger);
            color: var(--color-dark);
          }
        }

        h4 {
          font-size: 1.6rem;
          color: var(--color-dark);
          font-weight: 600;
        }
      }
    }
  }

  .title {
    padding: 1.6rem 2.4rem;
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--color-dark);
  }

  .activetrade-item {
    justify-content: center;
    width: 20%;
    padding: 2.4rem;
    border-right: var(--border);
    height: 100%;

    &:first-child {
      text-align: center;
    }

    &:last-child {
      border-right: none;
    }

    p.lenght {
      background-color: var(--color-primary);
      padding: 0.8rem 1.6rem;
      border-radius: 0.5rem;
      color: var(--color-white);
      font-size: 1.4rem;
      display: inline-block;
    }

    h3.status {
      color: var(--color-darkgrey);
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
    }

    .order {
      display: flex;
      align-items: center;

      span {
        padding: 0.5rem;
        font-size: 1.6rem;
        border-radius: 0.4rem;
        font-weight: 600;
        margin-right: 0.8rem;

        &.entry {
          background-color: var(--border-color);
          color: var(--color-darkgrey);
        }

        &.buy {
          background-color: var(--color-primary);
          color: var(--color-white);
        }

        &.sell {
          background-color: var(--color-danger);
          color: var(--color-white);
        }
      }
    }

    h3.price {
      font-size: 2.4rem;
      font-weight: 600;
      margin: 1.2rem 0;
      color: var(--color-dark);
    }

    button.placeOrder {
      background-color: var(--color-primary);
      padding: 0.8rem 1.6rem;
      border-radius: 5rem;
      color: var(--color-white);
      font-size: 1.4rem;
      text-transform: capitalize;

      &.waiting {
        background-color: var(--color-lightgrey);
      }
    }

    p.dateTime {
      font-size: 1.4rem;
      color: var(--color-dark);
      margin-top: 1.2rem;
    }

    @media screen and (max-width:1199px) {
      width: calc(33.33% - 5rem);
      margin-bottom: 1rem;

      &:nth-child(3) {
        border-right: none;
      }
    }

    @media screen and (max-width:767px) {
      width: 100%;
      border-right: none;
      border-bottom: var(--border);
    }
  }
}