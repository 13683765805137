.tabelBox {
  border: var(--border);
  border-radius: 0.8rem;

  &.bt-none {
    border-top: none;
  }

  Table table.table.tableData {
    tbody {
      tr {
        td {
          span {
            font-weight: 500;
            font-size: 1.4rem;
          }

          &:nth-child(3) {
            span {
              background-color: var(--color-lightdanger);
              padding: 1rem 2rem 1rem 2.6rem;
              border-radius: 0.4rem;
              color: var(--color-danger) !important;
              font-weight: 500;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0.8rem;
                transform: translateY(-50%);
                background-image: url("../../../../images/sell.png");
                width: 1rem;
                height: 0.6rem;
                background-repeat: no-repeat;
                background-size: 100%;
              }
            }
          }
        }
      }
    }
  }
}
