table.table.allowTable {
  tbody {
    tr {
      td {
        padding: 0.5rem 1rem !important;

        .brokerName {
          display: flex;
          align-items: center;
          justify-content: left;
          font-size: 1.4rem;
          color: var(--color-dark);
          padding: 0.5rem 0;

          img {
            height: 3.5rem;
            width: auto;
            border-radius: 0.6rem;
            margin-left: 1rem;
          }
        }

        .inputFields {
          .MuiFormControlLabel-root {
            margin-right: 0;
            margin-left: 1rem;

            span.MuiTypography-root {
              padding: 0.5rem;
            }
          }

          .MuiSvgIcon-root {
            left: auto;
          }
        }

        a.dematLink {
          font-size: 1.4rem;
          color: var(--color-primary);
          text-decoration: underline !important;
        }
      }
    }
  }
}

.textFields {
  display: flex;
  .MuiInputAdornment-root {
    p {
      font-size: 1.4rem;
      color: var(--color-dark);
    }
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 1rem;

    span.MuiTypography-root {
      padding: 0.5rem;
    }
  }

  .MuiSvgIcon-root {
    left: auto;
  }
}

.logoSize-note {
  display: flex;
  align-items: center;

  p {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-dark);
  }

  span {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-darkgrey);
    margin-left: 0.5rem;
  }
}

// ========= Social Profile update
h4.social_tagline {
  font-size: 1.6rem;
  color: var(--color-darkgrey);
  margin-bottom: 2rem;
}

.social_link_update {
  width: calc(100% - 2.5rem);
  border: var(--border);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  display: inline-flex;
  align-items: center;
  justify-content: left;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: rgba($color: #999999, $alpha: 0.06);

  .social_icon {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    padding: 1rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;

    &.youtube {
      background-color: #ff0000;
    }

    &.twitter {
      background-color: #00a6de;
    }

    &.facebook {
      background-color: #334c8c;
    }
    &.faq {
      background-color: #111e3e;
    }
    &.privacy {
      background-color: #d53301;
    }

    &.whatsapp {
      background-color: #4caf50;
    }

    &.insta {
      background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
    }

    &.telegram {
      background-color: #24b5ea;
    }

    &.linkedin {
      background-color: #007ab9;
    }

    &.android {
      background-color: #008000;
    }

    &.ios {
      background-color: #e21824;
    }

    @media screen and (max-width: 1399px) {
      height: 4rem;
      width: 4rem;
    }

    @media screen and (max-width: 767px) {
      height: 2.6rem;
      width: 2.6rem;
    }
  }

  .social_input {
    margin-left: 1rem;
    width: calc(100% - 5rem);
    position: relative;

    .inputFields {
      border-bottom: var(--border);
      padding-left: 0.4rem;

      .MuiInputAdornment-root {
        p {
          font-size: 1.4rem;
          color: var(--color-dark);
        }
      }

      input {
        border: none;
        padding-left: 0 !important;
        padding-right: 7.5rem !important;
      }
    }

    .social_action {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      z-index: 1;

      button {
        min-height: 3rem;
        min-width: 3rem;
        border-radius: 0.4rem;
        padding: 0.5rem;
        color: #ffffff;

        svg {
          font-size: 1.8rem;
        }

        &.remove_link {
          background-color: var(--color-danger);
        }

        &.save_link {
          background-color: var(--color-primary);
          margin-right: 0.4rem;
        }

        @media screen and (max-width: 767px) {
          min-height: 2.6rem;
          min-width: 2.6rem;
        }
      }

      @media screen and (max-width: 767px) {
        top: -1rem;
      }
    }
  }
}
