.overAll-view {
  margin-top: 4rem;

  h2.adminD-title {
    font-size: 2rem;
    padding: 1.2rem 2.6rem;
    background-color: var(--color-extralightblue);
    display: inline-block;
    color: var(--color-darkblue);
    font-weight: 600;
    text-transform: uppercase;
  }

  div.overAll-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    div.overAll-item {
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      width: calc(20% - 2rem);
      border: var(--border);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 9rem;

      &:nth-child(5n + 5) {
        margin-right: 0;
      }

      div.overAll-left {
        display: flex;
        align-items: center;
        padding: 1rem;

        div.overAll-icon {
          height: 4rem;
          width: 4rem;
          border-radius: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1.6rem;
        }

        p {
          font-size: 1.4rem;
          color: var(--color-dark);
          width: calc(100% - 5rem);
          font-weight: 600;
        }
      }

      div.overAll-count {
        border-left: var(--border);
        padding: 1rem;
        min-width: 4.5rem;
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.4rem;
        color: var(--color-bluegrey);
        font-weight: 600;
      }

      &:first-child {
        div.overAll-icon {
          background-color: #bbdcff;
        }
      }

      &:nth-child(2) {
        div.overAll-icon {
          background-color: #90e7a3;
        }
      }

      &:nth-child(3) {
        div.overAll-icon {
          background-color: #ffadad;
        }
      }

      &:nth-child(4) {
        div.overAll-icon {
          background-color: #96c1ff;
        }
      }

      &:nth-child(5) {
        div.overAll-icon {
          background-color: #ffcc73;
        }
      }

      &:nth-child(6) {
        div.overAll-icon {
          background-color: #c7d1ff;
        }
      }

      &:last-child {
        div.overAll-icon {
          background-color: #bbdcff;
        }
      }

      @media screen and (max-width: 1499px) {
        width: calc(25% - 2rem);

        &:nth-child(4n + 4) {
          margin-right: 1.5rem;
        }

        &:nth-child(5n + 5) {
          margin-right: 1.5rem;
        }
      }

      @media screen and (max-width: 1199px) {
        width: calc(33.33% - 1.8rem);

        &:nth-child(3n + 3) {
          margin-right: 1.5rem;
        }

        &:nth-child(2n + 2) {
          margin-right: 1.5rem;
        }
      }

      @media screen and (max-width: 767px) {
        width: calc(50% - 1.5rem);

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }

      @media screen and (max-width: 575px) {
        width: 100%;
        margin: 0 0 1rem 0 !important;
      }
    }
  }
}
