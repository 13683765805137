h2.title {
  font-size: 2rem;
  color: var(--color-darkblue);
  font-weight: 600;
}

.plan-card {
  background-color: var(--color-white);
  border: var(--border);
  border-radius: 0.5rem;
  padding: 1rem;

  &.active {
    border-left: 3px solid #24a959;
  }

  &.expiry {
    border-left: 3px solid var(--color-danger);
  }

  &.expiry-demo {
    border-left: 3px solid var(--color-success);
  }

  .plan-text {
    color: var(--color-dark);
    font-size: smaller;

    .plan-key {
      color: var(--color-darkgrey);
    }
  }

  .plan-value-primary {
    font-size: small;
    color: var(--color-success);
  }

  .plan-value-danger {
    font-size: small;
    color: var(--color-success);
  }

  .plan-text {
    font-size: 1.4rem;
    color: var(--color-darkgrey);
  }

  .plan-content {
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    width: 100%;

    .plan-value-primary {
      font-size: 1.4rem;
      color: #24a959;
      font-weight: 500;
      margin: 0 0.2rem;
    }

    .plan-value-danger {
      font-size: 1.4rem;
      color: var(--color-danger);
      font-weight: 500;
      margin: 0 0.2rem;
    }
    .plan-value-danger-demo {
      font-size: 1.4rem;
      color: var(--color-success);
      font-weight: 500;
      margin: 0 0.2rem;
    }

    .plan_type {
      color: var(--color-dark);
      font-size: 1.2rem;
      margin-left: auto;
      opacity: 0.8;
    }
  }
}

div.planCard-flex {
  display: flex;
  align-items: center;
  justify-content: center;

  div.planCard {
    border: var(--border);
    border-radius: 0.5rem;
    margin-top: 2rem;
    border-width: 2px;
    margin-left: 1.6rem;
    width: calc(25% - 1.6rem);

    &:hover {
      border-color: var(--color-blue);
      border-width: 2px;
    }

    div.planCard-type {
      min-height: 12rem;
      width: 100%;
      padding: 1rem;

      p.planType {
        font-size: 2rem;
        color: var(--color-dark);
        text-align: center;
      }

      .planPrice {
        display: flex;
        align-items: end;
        justify-content: center;

        span {
          &:first-child {
            font-size: 3.5rem;
            color: var(--color-darkgrey);
          }

          &:nth-child(2) {
            font-size: 3.5rem;
            color: var(--color-dark);
            font-weight: 600;
          }

          &:last-child {
            font-size: 1.4rem;
            color: var(--color-darkgrey);
            margin-bottom: 1rem;
          }
        }
      }

      .save {
        font-size: 1.4rem;
        color: var(--color-darkgrey);
        text-align: center;

        span {
          font-size: 1.4rem;
          color: var(--color-blue);
          font-weight: 500;
          margin-right: 0.5rem;
          display: block;
          width: 100%;

          @media screen and (max-width: 1399px) {
            display: block;
            text-align: center;
          }
        }

        del {
          color: var(--color-white);
          background-color: var(--color-blue);
          padding: 0.4rem 1rem;
          border-radius: 5rem;
          display: inline-block;
          margin-left: 0.5rem;
          font-size: 1.2rem;

          @media screen and (max-width: 1399px) {
            display: inline-block;
            text-align: center;
          }
        }

        @media screen and (max-width: 1399px) {
          display: block;
          text-align: center;
        }
      }

      @media screen and (max-width: 991px) {
        height: auto;
      }
    }

    div.planCard-content {
      padding: 1rem;
      border-top: var(--border);
      height: 30rem;
      display: grid;
      align-content: space-between;

      ul {
        padding: 0;

        li {
          position: relative;
          padding-left: 2.6rem;
          font-size: 1.4rem;
          color: var(--color-darkgrey);
          font-weight: 400 !important;
          display: flex;
          align-items: center;
          justify-content: left;
          word-break: break-all;

          &:after {
            content: "";
            position: absolute;
            top: 1rem;
            left: 0;
            background-image: url("../../images/check.png");
            height: 1.6rem;
            width: 1.6rem;
            background-size: 100%;
          }
        }
      }

      button.planCard-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-light);
        color: var(--color-darkgrey);
        font-size: 1.4rem;
        border: var(--border);
        margin-top: 1rem;

        &:hover {
          background-color: var(--color-blue);
          color: var(--color-white) !important;
        }

        svg {
          margin-right: 1rem;
        }
      }

      @media screen and (max-width: 1199px) {
        height: auto;
      }
    }

    &.premium {
      background-color: var(--color-darkblue);
      border-color: var(--color-darkblue);

      * {
        color: #ffffff !important;
      }

      .save {
        span {
          color: #f39a00 !important;
          margin-right: 0.5rem;
        }

        del {
          background-color: #f39a00 !important;
          margin-left: 0.5rem;
        }
      }

      button.planCard-btn {
        background-color: var(--color-blue) !important;
        color: var(--color-white) !important;
      }
    }

    @media screen and (max-width: 1299px) {
      width: calc(33.33% - 1.6rem);
    }

    @media screen and (max-width: 991px) {
      width: calc(40% - 1.6rem);
    }

    @media screen and (max-width: 767px) {
      width: calc(50% - 1.6rem);
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1299px) {
    flex-wrap: wrap;
  }
}

.notes {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 1rem;
  padding-left: 1.5rem;

  p {
    font-size: 1.3rem;
    font-weight: 600;
    margin-right: 0.5rem;
    color: var(--color-dark);
  }

  span {
    font-size: 1.3rem;
    color: var(--color-darkgrey);
  }
}
